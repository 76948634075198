#new-version-available-id {
  p > span {
    display: block;
  }
  #new-version-available-loader {
    display: none;
    position: absolute;
    inset: 0;
    left: 4rem;
    background: rgba(255,255,255,0.8);
    z-index: 4;
  }
  button {
    cursor: pointer;
    position: absolute;
    inset: 0;
    border: 0;
    background: transparent;
    outline-color: #41a0f2;
    outline-offset: -1px;
  }
  #new-version-available-text {
    position: relative;
    z-index: 3;
  }
  #new-version-available-error {
    display: none;
    position: absolute;
    inset: 0;
    font-size: 12px;
    cursor: default;
    z-index: 999;
    padding: 9px 10px;
    background: white;
    box-shadow: inset 0 0 0 2px red;
    &-title {
      font-weight: bold;
      color: red;
      margin-right: 4px;
    }
  }
}