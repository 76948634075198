@use "sass:math";
// ?  "_system-dashboard-wheel-config" imported here

.table-holder .refresh-button-holder {
  vertical-align: middle;
  text-align: center;
}
.table-refresh-icon {
  font-size: 1.6rem;
  color: $green;
  animation: animationRotate 0.9s linear infinite;
  animation-play-state: paused;
  &.isRefreshing {
    pointer-events: none;
    animation-play-state: running;
  }
}

.refresh-dial__holder {
  position: absolute;
  top: 2.4rem;
  right: 2.4rem;
  z-index: 1;
}

.system-dashboard-layout {
  display: flex;
  height: 100%;
  position: relative;
  .left {
    flex: 0 0 1%;
    position: relative;
  }
  .right {
    flex: 1 1 auto;
    padding-left: 8px;
    position: relative;
  }
}

$mini-3-col: math.div(100%, 3);
$mini-2-col: math.div(100%, 2);

.table-holder {
  height: 100%;
}

.mini-wheels {
  margin: 1px -2px;
  margin-left: 0.1px;
}

.mini-wheel {
  position: relative;
  cursor: pointer;
  text-align: center;
  // margin-top:spToRem(8);
  // margin:4px 2px;
  margin-top: 8px;
  display: inline-block;
  min-width: 174px;
  overflow: hidden;
  transition: background-color 0.2s ease-in;
  // width:174px;
  // width:min-content;
  > div:nth-child(1) {
    display: inline-block;
    margin-bottom: spToRem(8);
    font-size: 0; // ! hack to remove spacing - might be some other issues
  }
  .mini-title {
    // text-align:left;
    display: inline-block;
    width: 100%;
    height: spToRem(36);
    vertical-align: middle;
    margin-top: spToRem(8);
  }
  h5 {
    // margin-top:spToRem(8);
    // margin-bottom:0;
    margin: 0;
    font-size: spToRem(12);
  }
  &:after,
  &:before {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    transition: opacity 0.3s ease-in, color 0.3s ease-in;
  }
  &:after {
    content: " ";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 spToRem(48) spToRem(48) 0;
    // border-top-right-radius:4px;
    border-color: transparent $primary transparent transparent;
  }
  &:before {
    content: "\e5ca";
    font-family: $font-material;
    color: white;
    font-size: spToRem(24);
    z-index: 1;
    margin: -2px 2px 0 0;
  }
  &:hover {
    @include customize() {
      background: getValue(system-dashboard-mini-wheel-hover);
    }
    &:before {
      opacity: 1;
      color: $primary;
    }
    // &:after {
    //   opacity:.5;
    // }
  }
  &.active {
    &:before {
      color: white; //reset
    }
    &:before,
    &:after {
      opacity: 1;
    }
  }
}

// @include media-max(2390px) {
//   .mini-wheel {
//     width:calc(#{$mini-3-col} - 1rem);
//     &:nth-child(even) {
//       margin-left:0;
//     }
//     &:nth-child(4n+2),
//     &:nth-child(4n+3) {
//       margin-left:10px;
//     }
//   }
// }

.table-holder {
  .grid-table {
    min-width: 100%;
    width: fit-content;
  }
  .table {
    margin-bottom: 1.2rem;
  }
  .graph-holder {
    // width: 1px;
    padding: spToRem(2) spToRem(4);
  }
  .brag-holder {
    text-align: center;
  }
  // .row > li + li {
  //   width: 1px;
  // }
  .weight__holder {
    margin: 2px 0 -1px 0;
    &--date input:disabled {
      @extend %empty-indicator;
    }
    input {
      width: 58px;
      &[data-can-edit="true"] {
        border-color: $blue;
      }
    }
  }
}

.table-footer {
  font-size: 1.1rem;
  margin-bottom: 2.4rem;
}

// .right .scroller {
//   overflow-x: auto;
//   overflow-y: auto;
//   max-height: calc(100vh - 200px);
//   padding-right:12px;
//   position:sticky;
//   top:94px;
// }
.left .scroller {
  padding-right: 2px;
  position: sticky;
  top: $header-height + 8px;
}
.left .scroller,
.right .scroller {
  overflow-x: hidden;
  // overflow-y: hidden;
  // overflow-y: auto;
  // overflow-y: overlay;
  max-height: calc(100vh - 20.2rem); // 148 + 64 + extra 20
  // padding-right:12px;
  // position:sticky;
  // top:94px;
  transform: translateZ(0); //perf fix
  will-change: transform; //
  // &:hover {
  //   overflow-y: auto;
  //   overflow-y: overlay;
  // }
}

.left .scroller {
  overflow-y: hidden;
  scrollbar-gutter: stable;
  &:hover {
    overflow-y: auto;
    overflow-y: overlay;
  }
}

.specialScroller {
  // max-width: 920px;
  margin-bottom: 28px;
}

@media only screen and (min-width: 1921px) {
  .specialScroller {
    min-width: 740px;
    max-width: fit-content;
  }
}

.sticky.title-in-header .system-dashboard-layout .left .scroller {
  max-height: calc(100vh - 9.2rem);
}

.sticky .system-dashboard-layout .left .scroller::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 1.4rem;
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.07);
  background: rgba(0, 0, 0, 0.07);
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

#system-dashboard {
  padding-bottom: 0 !important;
}

// .right .scroller {
//   padding-right:16px;
// }

.indicator-modal-items {
  position: relative;
  h3 {
    margin-bottom: 0.6rem;
  }
  li {
    padding: 0.4rem 0;
  }
  ul + h3 {
    margin-top: 2rem;
  }
  .toggle-container {
    position: absolute;
    right: 0;
    top: 0;
    a {
      font-size: 1.1rem;
      font-weight: 600;
    }
  }
}
$ie-padding: 24;
@import "./_system-dashboard-wheel-config.scss";

// IE 10, IE 11
// .ie {
  // .system-dashboard-layout .left .scroller {
  //   max-height: calc(100vh - 17.4rem);
  // }
  // .system-dashboard-layout .left {
  //   min-width: getWheelSize(xl) + $ie-padding;
  //   max-width: getWheelSize(xl) + $ie-padding;
  // }
  // .specialScroller {
  //   @extend .scroller;
  // }
  // .left .scroller {
  //   overflow-y: auto;
  //   -ms-overflow-style: scrollbar;
  //   max-height: none !important; // no scrollbars left side
  // }
  // .right .specialScroller {
  //   -ms-overflow-style: scrollbar;
  //   overflow-x: auto;
  // }
  // .system-dashboard-layout .right .scroller {
  //   overflow-y: auto !important;
  // }
  // .system-dashboard-layout .left .scroller,
  // .specialScroller {
  //   max-height: calc(100vh - 19.4rem);
  // }
//   .system-dashboard-layout .right .inline-error {
//     height: auto !important;
//     padding-top: 140px;
//   }
// }

// #HomeFix2 - Edge

@include media-min(1860px) {
  .system-dashboard-layout .table-holder {
    .brag {
      font-size: 1.4rem;
    }
    .table .th,
    .table .cell:not(.brag-holder) {
      font-size: 1.4rem;
      padding-top: 1.3rem;
      padding-bottom: 1.3rem;
    }
    .table-footer {
      font-size: 1.2rem;
    }
  }
}

@include media-max($breakpoint-lPlus) {
  .system-dashboard-layout .table-holder {
    .brag:not(.brag-tag) {
      width: 50px;
      height: 3rem;
      line-height: 3rem;
    }
    .table .normal-holder,
    .table .th {
      padding: 0.6rem 0.8rem;
    }
    .table .th {
      font-size: 1.1rem;
    }
    .table .th + .th {
      padding-left: 0.4rem;
      padding-right: 0.4rem;
    }
  }
}


// modal:
#modal-escalation-actions {
  // > article > .tabs > .tab-content {
  //   height: 60vh;
  //   overflow: auto;
  //   padding-bottom: 1px;
  // }
  .live-system-actions-tabs .tab-content {
    display: block;
  }
  .scrollbar-content, .live-system-actions-tabs .tab-content {
    overflow: auto;
    padding-right: $scrollbar-width;
    margin-right: -($scrollbar-width + 0px);
    padding-bottom: 1px;
  }
  .meta {
    @include customize() {
      color: getValue(metatext);
    }
    font-size: 1rem;
    .info-icon {
      font-size: 1.1rem;
      margin-top: -0.1rem;
      margin-left: 0.4rem;
    }
  }
}

.escalation-header {
  > * {
    vertical-align: top;
  }
  .react-datepicker-wrapper {
    width: 116px;
  }
  .react-datepicker__tab-loop {
    display: inline-block;
  }
  @include verticalMiddleHack();
}

.escalation-user {
  background: rgba($primary, 0.1);
  border: 1px solid rgba($primary, 0.23);
  padding: 1px 6px;
  border-radius: 2px;
  white-space: nowrap;
  &.current {
    // font-weight: bold;
    background: rgba($primary, 0.4);
  }
  &-list {
    margin: -4px;
    margin-top: 4px;
    > span {
      margin: 4px;
      display: inline-block;
    }
  }
  &-more {
    color: $blue;
    cursor: pointer;
    font-weight: bold;
    padding: 1px 6px;
    border: 1px solid transparent;
    // background: rgba($primary, 0.05);
    background-color: rgba(0,0,0,0);
    transition: background-color 0.2s ease-in-out;
    &:hover {
      background-color: rgba($primary, 0.1);
    }
    min-width: 40px;
    text-align: center;
    margin-right: -16px !important;
    // transform-origin: left center;
    // &.isExpanded {
      // transform: scaleX(3);
    // }
    // > span {
    //   transform: scale(3, 2);
    // }
  }
}

.escalation-plans-table {
  ul.list li {
    border-bottom: 1px solid #d1d5da;
    padding-bottom: 12px;
    margin-bottom: 6px;
    &:last-child {
      border-color: transparent;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}

.escalation-status {
  display: inline-block;
  padding: 1px 6px;
  border-radius: 2px;
  font-weight: bold;
}

.escalation-bar-holder {
  margin-bottom: 1.2rem;
  // + .escalation-bar-holder {
  //   margin-top: 12px;
  // }
  header {
    display: table;
    width: 100%;
    > * {
      display:table-cell;
      &:last-child {
        width: 1px;
        white-space: nowrap;
      }
    }
  }
}

.escalation-bar {
  width: 100%;
  white-space: nowrap;
  > div {
    display: inline-block;
    $escalation-border-radius: 3px;
    opacity: 1;
    transition: opacity .2s ease-in-out;
    &:hover {
      opacity: 0.8;
    }
    padding: 0 6px;
    height: 18px;
    line-height: 18px;
    overflow: hidden;
    &:first-child {
      border-top-left-radius: $escalation-border-radius;
      border-bottom-left-radius: $escalation-border-radius;
    }
    &:last-child {
      border-top-right-radius: $escalation-border-radius;
      border-bottom-right-radius: $escalation-border-radius;
    }
  }
}
// escalation users modal
$radio-size: 16px;
.escalation-users-row {
  display: flex;
  justify-content: stretch;
  + .escalation-users-row {
    margin-top: 24px;
    border-top: 1px solid $lightgray;
    padding-top: 24px;
  }
  > div {
    display: inline-block;
    vertical-align: top;
    // margin-left: -12px;
    // padding: 0 12px;
    // + div {
    //   padding-left: 12px;
    // }
  }
  .hide-4-5-column {
    tr > {
      *:nth-child(4), *:nth-child(5) {
        display: none !important;
      }
    }
  }
  .active-user-row {
    background: rgba($primary, 0.1);
  }
  .escalation-users-table__holder {
    table {
      position: relative;
    }
    tbody tr {
      cursor: pointer;
      &:hover {
        background: rgba($primary, 0.05);
      }
      &.selected-row {
        background: rgba($primary, 0.2);
        .table-link:after {
          display: inline-block;
        }
      }
    }
  }
  .escalation-users-user-table__holder {
    margin-left: 12px;
    position: relative;
    // .user-table-vertical-line {
    //   position: absolute;
    //   left: 0;
    //   height: 95%;
    //   top: 5px;
    //   width: 1px;
    //   z-index: -2;
    //   background: $primary;
    // }
    .user-table-vertical-line {
      max-height: 0 !important;
    }
    table.bordered {
      border-color: $primary !important;
    }
  }
}

.table-link {
  &:after 
  // ,&:before 
  {
    // font-size: 0;
    content: " ";
    background: $primary;
    // display: inline-block;
    position: absolute;
    margin-top: math.div($radio-size, 2);
  }
  &:after {
    z-index: 1;
    display: none;
    height: 1px;
    width: 35px;
  }
  // &:before {
  //   display: none;
  //   z-index: -1;
  //   @include customize() {
  //     background-color: getValue(modal-bg);
  //   }
  //   width: 50px;
  //   height: calc(100% - 75px);
  // }
}

.selected-row {
  .table-link:before {
    display: inline-block;
  }
  ~ tr .table-link:before {
    display: none;
  }
}

.normal-holder {
  &__wrapper {
    display: flex;
    align-items: center;
  }
  &__actions {
    margin-left: auto;
    margin-right: -1rem;
  }
}

/* PTL */
:root {
  --ptl-primary: 83, 109, 180;
}

.table.grid-table .row.ptl__row {
  display: grid;
  grid-template-columns: subgrid;
  grid-column: 1 / -1;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.3s ease-out;
  &[aria-hidden="false"] {
    grid-template-rows: 1fr;
  }
  > * {
    overflow: hidden;
    grid-row: 1 / span 2;
    border-top-width: 0;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.ptl-btn__action {
  cursor: pointer;
  display: flex;
  align-items: center;
  &__icon-wrapper {
    display: grid;
    place-items: center;
    position: relative;
    > * {
      grid-area: 1 / 1;
    }
  }
  &__icon {
    font-size: 34px;
    margin: -10px 0;
  }
  &__label {
    font-family: $font-family-heading;
    font-size: 10px;
    font-weight: 700;
    line-height: 1;
    background: white;
    align-self: end;
    padding: 1px 0;
    border-radius: 8px;
    margin-bottom: -4px;
    margin-left: 1px;
    letter-spacing: 1px;
    &:first-letter {
      letter-spacing: 0;
    }
  }
  transition: color 0.2s ease;
  &__chevron {
    transition: opacity 0.2s ease, transform 0.2s ease;
    transform: rotate(0deg);
    font-size: 18px;
    opacity: 0.4;
  }
  &[aria-expanded="true"] {
    color: rgb(var(--ptl-primary));
  }
  &[aria-expanded="true"], &:hover {
    .ptl-btn__action__chevron {
      opacity: 1;
    }
  }
  &[aria-expanded="true"] .ptl-btn__action__chevron {
    transform: rotate(180deg);
  }
}

[class^="theme-"] .btn.ptl-btn {
  margin: 0.2rem 0 0.6rem;
  font-size: 1.1rem;
  padding: 0;
  border-radius: 8px;
  line-height: 2.4rem;
  height: auto;
  white-space: nowrap;
  color: rgb(var(--ptl-primary));
  background: rgba(var(--ptl-primary), 0.1);
  &:hover {
    background: rgba(var(--ptl-primary), 0.2);
  }
}

@media only screen and (min-width: 1921px) {
  [class^="theme-"] .btn.ptl-btn { 
    margin: 0 0 0.8rem;
  }
}