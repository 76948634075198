$arrow-size: 8px;
$map-color: #eee9e2;
$map-header-height: 48px;
$x-padding: 14px;

.map-overlay {
  // ! TODO prettify
  // box overwrites
  width: auto;
  padding: 0;
  //
  min-width: 20px;
  min-height: 20px;
  // overflow: hidden;
  position: absolute;
  // top: 24px;
  // right: 24px;
  > section,
  > header {
    padding: 12px $x-padding;
  }
  .select.indicator-select.indicator-group__select--seamless {
    .select__option__fixer {
      min-width: auto !important;
    }
    .select__option {
      white-space: normal;
      // .radio, .checkbox {
      //   > span {
      //     display: flex;
      //     min-height: 2.4rem;
      //     height: auto;
      //   }
      // }
    }
    .select__menu {
      box-shadow: 0 1px 5px -1px rgba(0, 0, 0, 0.15), 0 1px 0 0 rgba(0, 0, 0, 0.05);
      margin-top: 1px;
      left: -($x-padding);
      right: -($x-padding);
      &-list {
        padding-left: $x-padding;
        padding-right: $x-padding;
        padding-bottom: $x-padding;
      }
      // margin-right: -($x-padding);
    }
  }
  > header {
    padding-top: 0;
    padding-bottom: 0;
    height: $map-header-height;
    > * {
      height: 100%;
      // @include verticalMiddleHack();
    }
  }
  header {
    background: #f1f5fb;
    h3 {
      font-size: 1.4rem;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // display: table;
      width: 100%; // i think for ie11
      // > * {
      //   display: table-cell;
      //   &:last-child {
      //     text-align:right;
      //   }
      // }
    }
  }
  h4 {
    margin-bottom: 0.6rem;
    font-size: 1.2rem;
  }
  // background: white;
}

.google-map-holder {
  position: relative;
  > div {
    height: 100%;
  }
  .l-holder {
    position: absolute;
  }
}

.google-map {
  height: 100%;
  background-color: $map-color;
  .l-holder, .map-loader-holder {
    padding: 12px;
  }
  .l-holder {
    top: 0;
    left: 0;
    height: auto;
    pointer-events: none;
  }
  .map-loader-holder {
    height: 100%;
    background: $map-color;
  }
}

.indicator-circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin-right: 8px;
  vertical-align: -1px;
}

.map-tooltip {
  // position: absolute;
  backface-visibility: hidden;
  position: fixed;
  will-change: transform;
  z-index: 99;
  // transition: transform 0.1s ease-out;
  // top: 0;
  // left: 0;
  padding: 8px;
  font-size: 1.2rem;
  box-shadow: 0 4px 20px 4px rgba(0, 20, 60, 0.1),
    0 4px 80px -8px rgba(0, 20, 60, 0.2);
  border: 1px solid $blue;
  border-radius: 5px;
  > div {
    + div {
      margin-top: 2px;
    }
    &.indicator-colour-list {
      margin-top: 4px;
    }
  }
  &.arrow-top:after,
  &.arrow-top:before,
  &.arrow-bottom:after,
  &.arrow-bottom:before,
  [class^="arrow-"],
  [class^="arrow-"]:before {
    content: " ";
    position: absolute;
    display: block;
    border-style: solid;
    border-color: transparent;
  }
  [class^="arrow-"]:before {
    z-index: -1;
    top: -($arrow-size);
    left: -($arrow-size) - 1px;
    border-width: $arrow-size + 1px;
  }
  &.arrow-top:after,
  &.arrow-bottom:after,
  [class^="arrow-"] {
    left: calc(50% - #{$arrow-size});
    border-width: $arrow-size;
  }
  &.arrow-top:before,
  &.arrow-bottom:before {
    left: calc(50% - #{$arrow-size + 1px});
    border-width: $arrow-size + 1px;
  }
  &.arrow-top:after,
  &.arrow-top:before,
  .arrow-top {
    bottom: 100%;
    border-top: none;
  }

  &.arrow-top:after,
  .arrow-top {
    border-bottom-color: white;
  }
  &.arrow-top:before,
  .arrow-top:before {
    border-bottom-color: $blue;
  }
  &.arrow-bottom:after,
  &.arrow-bottom:before,
  .arrow-bottom {
    top: 100%;
    border-bottom: none;
  }

  &.arrow-bottom:after,
  .arrow-bottom {
    border-top-color: white;
  }
  &.arrow-bottom:before,
  .arrow-bottom:before {
    border-top-color: $blue;
  }
}


// Popover brags
.popover-brag-table {
  display: inline-block !important;
  // .tbody {
  //   display: flex !important;
  //   flex-direction: column;
  // } 
  // .row {
  //   display: flex !important;
  //   align-items: center;
  // }
  // .cell {
  //   &:nth-child(1) {
  //     flex-grow: 1;
  //   }
  //   + .cell {
  //     flex-basis: 20px;
  //     flex-shrink: 0; 
  //   }
  // }

  .row .cell {
    &:first-child {
      padding-left:0 !important;
    }
    &:last-child {
      padding-right:0 !important;
    }
  }
  .popover-brag-mini {
    font-size: 1.1rem;
    // width: 40px;
    width: 48px;
    height: 2.4rem;
    line-height:2.4rem;
  }
  
}



.map-overlay {
  &.hover-popover {	
    $clip-max: 102%;
    top: 24px;	
    left: 24px;	
    min-width: 260px;	
    max-width: 35%; 	
    z-index: 1;	
    transition: clip-path 0.2s ease-in-out;
    clip-path: polygon(0 0, $clip-max 0, $clip-max $clip-max, 0 $clip-max);	
    &.minimized {
      clip-path: polygon(0 0, $clip-max 0, $clip-max 48px, 0 48px);
    }
    > section {	
      max-height: 60vh;	
      max-height: calc(100vh - 168px);	
      overflow-y: auto;	
      overflow-y: overlay;	
      overflow-x: hidden;	
    }	
    .hospital-close {	
      opacity: .5;	
      transition: opacity .2s ease-in-out;	
      &:hover {	
        opacity:1;	
      }	
    }	
  }
}

[class^="theme-"] .google-map-holder {
  .select.indicator-select .select__menu-list {
    max-height: calc(100vh - 230px) !important;
  }
  .has-error.indicator-select .select__control {
    border-color: $red;
  }
  .popover-brag-table .tbody {
    padding-right: 6px;
  }
}

// .ie .map-overlay {
//   &.hover-popover {	
//     &.minimized {
//       > section {
//         display: none;
//       }
//     }
//   }
// }