// ! to refactor
.table.grid-table {
  display: grid;
  border-collapse: collapse;
  // min-width: 100%;
  .thead, .tbody, .row {
    display: contents;
  }
  .th {
    align-content: end;
  }
  .cell {
    align-content: center;
    width: auto; // ! only resilience?
  }
  &.shadow-hover-effect {
    .row { 
      grid-column: 1 / -1;
      display: grid;
      grid-template-columns: subgrid;
    }
  }
}